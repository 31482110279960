import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 300.000000 398.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,398.000000) scale(0.100000,-0.100000)">
 

 <path d="M1175 2067 c-176 -353 -321 -644 -322 -647 -2 -3 290 -5 647 -5 358
0 649 2 647 5 -1 3 -146 294 -322 647 -176 353 -322 641 -325 641 -3 0 -149
-288 -325 -641z m620 -28 c158 -314 283 -574 280 -578 -9 -9 -1129 -11 -1144
-2 -9 6 72 176 273 579 158 315 291 572 296 572 5 0 138 -257 295 -571z"/>
<path d="M1457 2458 c-34 -46 -457 -894 -457 -917 0 -10 9 -26 21 -37 20 -18
44 -19 463 -21 242 -1 453 1 467 4 60 12 59 89 -1 153 l-28 30 -320 0 c-178 0
-323 4 -326 9 -7 10 212 451 224 451 8 0 120 -214 120 -229 0 -5 -42 -11 -94
-13 -77 -2 -98 -7 -116 -23 -32 -29 -62 -94 -56 -119 10 -40 44 -46 269 -46
231 0 260 6 265 51 3 27 -305 657 -348 712 -28 36 -54 34 -83 -5z m229 -354
l180 -364 -247 0 -247 0 30 55 30 55 129 0 129 0 -92 185 c-51 102 -95 185
-98 185 -3 0 -71 -133 -153 -295 l-147 -295 363 -1 362 0 27 -55 27 -54 -477
0 -478 0 236 474 c129 261 237 474 240 474 3 0 86 -164 186 -364z"/>
<path d="M666 1198 c-21 -11 -69 -51 -107 -88 -51 -52 -76 -69 -100 -72 -40
-4 -52 -28 -14 -28 l26 0 -25 -33 c-15 -19 -26 -41 -26 -50 0 -34 19 -24 54
28 35 53 38 55 81 55 l45 0 -25 -50 c-29 -57 -30 -60 -12 -60 24 0 44 32 101
158 73 160 73 176 2 140z m-18 -93 c-32 -64 -33 -65 -72 -65 l-40 0 64 65 c35
36 68 65 72 65 4 0 -7 -29 -24 -65z"/>
<path d="M773 1172 c-57 -85 -114 -204 -111 -231 2 -20 9 -27 31 -29 22 -3 38
6 72 40 67 68 75 137 14 124 l-31 -6 23 38 c12 20 33 51 46 69 28 38 28 43 6
43 -10 0 -32 -22 -50 -48z m17 -137 c0 -20 -68 -95 -86 -95 -8 0 -14 5 -14 10
0 23 41 89 57 93 34 9 43 7 43 -8z"/>
<path d="M1302 1183 c-20 -21 -40 -37 -45 -35 -4 1 -6 -2 -3 -7 3 -5 -5 -32
-19 -59 -14 -28 -28 -64 -31 -81 -4 -18 -9 -30 -11 -27 -3 2 -21 -7 -41 -20
-20 -14 -42 -23 -51 -22 -28 6 -22 28 13 45 43 20 46 33 9 33 l-28 0 25 20
c14 10 27 15 31 9 7 -13 36 -1 43 17 8 21 -18 28 -57 15 -42 -14 -72 -45 -63
-66 7 -19 -40 -64 -67 -65 -15 0 -13 10 19 66 20 36 34 68 30 71 -12 12 -56
-17 -56 -37 0 -11 -9 -34 -20 -52 -11 -18 -20 -41 -20 -50 0 -25 29 -37 60
-26 17 6 37 5 55 -2 24 -9 36 -7 77 14 36 19 48 22 48 11 0 -27 34 -35 71 -17
32 16 35 16 49 -1 13 -15 20 -16 46 -7 22 8 39 8 59 0 23 -9 35 -7 67 9 39 19
40 19 59 0 17 -17 22 -18 47 -6 22 11 32 11 51 1 27 -14 73 -4 102 22 11 10
19 12 19 6 0 -26 29 -41 64 -31 19 5 43 6 57 1 26 -10 72 0 95 19 12 10 17 8
28 -6 16 -22 64 -23 107 -1 26 13 31 13 36 0 19 -51 133 31 158 113 9 31 -35
49 -77 32 -30 -12 -78 -66 -78 -87 0 -18 -58 -43 -93 -40 -45 4 -46 31 -4 73
36 36 63 46 54 20 -9 -23 9 -26 28 -5 28 31 12 54 -32 47 -37 -6 -103 -60
-103 -84 0 -18 -77 -66 -95 -59 -24 9 -18 22 23 47 l37 23 -30 5 -30 6 25 17
c17 13 26 15 31 6 6 -9 11 -8 23 1 24 20 20 41 -8 41 -37 0 -91 -42 -91 -70 0
-29 -58 -82 -81 -73 -25 9 -15 31 51 113 72 90 99 135 91 156 -21 55 -121 -48
-166 -174 -13 -34 -25 -60 -28 -57 -3 3 -15 -4 -27 -15 -24 -22 -72 -32 -76
-15 -2 6 12 19 29 30 37 24 40 35 10 35 l-23 1 24 20 c18 15 26 17 32 8 9 -16
39 5 39 27 0 35 -107 -5 -117 -43 -3 -13 -3 -27 0 -30 7 -6 -58 -53 -73 -53
-17 0 -11 20 27 91 34 62 41 69 76 74 37 7 48 32 13 28 -14 -1 -16 4 -12 23 8
32 -16 32 -34 0 -9 -18 -23 -26 -48 -28 -41 -4 -57 -28 -18 -28 l25 0 -32 -59
c-44 -81 -130 -137 -144 -94 -3 7 12 20 31 30 43 20 46 33 9 33 l-28 0 25 20
c16 12 28 15 36 9 16 -13 50 19 35 34 -27 27 -136 -41 -116 -73 8 -12 -44 -59
-66 -60 -39 0 -30 26 36 105 56 66 95 130 95 155 0 33 -39 24 -78 -17 -36 -39
-102 -164 -102 -195 0 -20 -36 -48 -62 -48 -30 0 -20 29 31 94 98 124 125 186
80 186 -12 0 -38 -17 -57 -37z m44 -9 c-10 -26 -98 -145 -103 -140 -11 10 87
156 104 156 3 0 2 -7 -1 -16z m114 10 c0 -10 -103 -155 -107 -150 -6 6 25 64
61 113 28 38 46 53 46 37z m455 -21 c-34 -58 -95 -137 -95 -124 0 26 84 151
102 151 5 0 2 -12 -7 -27z m365 -128 c0 -20 -68 -95 -86 -95 -21 0 -17 23 8
56 40 52 78 71 78 39z"/>
<path d="M1067 1143 c-10 -9 -9 -43 1 -43 12 0 32 23 32 37 0 14 -22 18 -33 6z"/>
<path d="M2524 1074 c-16 -8 -37 -25 -46 -37 -18 -22 -18 -22 -12 11 8 41 -11
43 -55 6 -17 -14 -31 -21 -31 -15 0 5 5 13 10 16 14 9 12 25 -3 25 -13 0 -97
-138 -97 -159 0 -22 30 -11 44 17 18 33 106 121 106 104 0 -6 -16 -36 -35 -66
-39 -61 -41 -66 -22 -66 6 0 41 32 76 71 68 76 88 85 46 21 -15 -23 -25 -51
-23 -64 5 -35 64 -33 91 4 26 35 14 49 -16 20 -23 -22 -47 -29 -47 -14 0 4 16
32 35 62 19 30 35 58 35 61 0 15 -29 16 -56 3z"/>
<path d="M838 1002 c-62 -104 -46 -116 37 -27 43 46 64 62 60 48 -9 -35 21
-29 40 7 8 16 15 34 15 40 0 19 -38 10 -71 -17 l-33 -28 18 28 c18 26 18 27 0
27 -13 0 -34 -25 -66 -78z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
